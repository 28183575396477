<template>
  <div class="container">


    <div class="bigBox">
      <div class="one" @click="kefuDialog">
        <img src="../assets/组.svg">
        <!-- <svg href="../assets/headset-one.svg"></svg> -->
      </div>
      <!-- <div class="two">
        <img src="../assets/tel.png">
      </div>
      <div class="three">
        <img src="../assets/qywechat.png">
      </div> -->
    </div>


    <div class="main" v-show="keDialog">
      <div class="box">
        <div class="title">
          <img src="" alt class="logo" />
          <span class="title-hn">联系在线管家</span>
          <span class="iconfont1" @click="hiddleDialog">&#xe709;</span>
          <span class="iconfont">&#xe656;</span>
        </div>
        <hr><hr>
        <div id="content" class="content" ref="chatContainer">
          <div v-for="(item, index) in info" :key="index">
            <div class="info_r info_default" v-if="item.Sender == '企小虎'">
              <img class="circle circle_r" src="../assets/qxhloge.jpg"></img>
              <div class="con_r con_text">
                <div>{{ item.Messagecontent }}</div>
                <!-- <div v-for="(item2, index) in item.question" :key="index">
                  <div
                    class="con_que"
                    @click="clickRobot(item2.content, item2.id)"
                  >
                    <div class="czkj-question-msg">
                      {{ item2.index }}
                      {{ item2.content }}
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div class="time_r">{{ this.getTodayTime()}}</div> -->
            </div>

              <div class="info_l" v-if="item.Sender != '企小虎'">
                <div class="con_r con_text">
                  <span class="con_l">{{ item.Messagecontent }}</span>
                  <span class="circle circle_l">
                    <img src="../assets/kfyh.png" class="pic_l" />
                  </span>
                </div>
                <!-- <div class="time_l">{{ item.time }}</div> -->
            </div>
          </div>
        </div>

        <div class="sendbox">
          <div class="setproblem">
            <textarea
              placeholder="请输入您的问题..."
              style="
                height: 68px;
                width: 100%;
                resize: none;
                padding-right: 80px;
                outline: none;
                border-color: #ccc;
                border-radius: 5px;
              "
              id="text"
              v-model="customerText"
              @keyup.enter="sentMsg()"
            ></textarea>
          </div>
          <div style="padding-top: 10px;">
            <div class="senbox_left__icon">
              <span class="iconfont2">&#xf01ef;</span>
              <span class="iconfont3">&#xe631;</span>
              <span class="iconfont4">&#xe636;</span>
            </div>
            <div class="sendbox_right_button">
              <button @click="sentMsg()" class="setproblems">
                <span style="vertical-align: 4px">发 送</span>
              </button>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
  <script>
import qs from "qs";
import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2'
export default {
  name: "onlineCustomer",
  components: {},
  computed: {},
  data() {
    return {
      keDialog:false,
      customerText: "",
      info: [
        // {
        //   type: "leftinfo",
        //   time: this.getTodayTime(),
        //   name: "robot",
        //   content: "你的知识产权管家，将竭诚为您服务：您可以直接咨询关于著作权和电子版权的所有问题，也可以拨打电话或者添加扫码微信号：18614063413",
        //   question: [],
        // },
        {
          Sender:"企小虎",
          Messagecontent:"您的知识产权管家，将竭诚为您服务：您可以直接咨询关于著作权和电子版权的所有问题，也可以拨打电话或者添加扫码微信号：18614063413",
        }
      ],
      timer: null,
      ip:null,
      time:"",
      zhiwen:"",
    };
  },
  created() {
    this.showTimer();
  },
  watch: {},
  methods: {
    kefuDialog(){
      this.keDialog=true
      const url = "/api/SetchatServlet";
      //let params = JSON.parse(sessionStorage.getItem('userInfo'));
      //console.log("参数", params);
      // let str = {
      //   name: this.ip,
      // };
      // let str1 = qs.stringify(str);
      // // console.log("参数", str1);
      // this.axios
      //   .post(url, str1)
      //   .then((res) => {
      //     this.info = [
      //       {
      //     Sender:"企小虎",
      //     Messagecontent:"您的知识产权管家，将竭诚为您服务：您可以直接咨询关于著作权和电子版权的所有问题，也可以拨打电话或者添加扫码微信号：18614063413",
      //   }
      //     ]; 
      //     //this.tableData = res.data;
      //     for(let i=0;i<res.data.length;i++){
      //       var obj = {
      //     Sender: res.data[i].Sender,
      //     Messagecontent:res.data[i].Messagecontent,
      //   };
      //     this.info.push(obj)
      //     }
      //     //this.Messagevolume=this.messages.length
      //   })
      //   .catch((err)=> {
      //    // console.log(err),
      //      // console.log(err.response.data),
      //       // this.$message({
      //       //   message: "失败！请检查网络",
      //       //   type: "warning",
      //       // });
      //   });
      this.selectchat();
    },
    hiddleDialog(){
      this.keDialog=false;
      this.stopTimer(); 
    },
    stopTimer(){clearInterval(this.timer);},
    // 用户发送消息
    sentMsg() {
      const date = new Date();
    this.getDate(date)
      clearTimeout(this.timer);
      this.showTimer();
      let text = this.customerText.trim();
      if (text != "") {
        // var obj = {
        //   type: "rightinfo",
        //   time: this.getTodayTime(),
        //   content: text,
        // };
        // this.info.push(obj);
        // this.appendRobotMsg(this.customerText);
        // this.customerText = "";
        // this.$nextTick(() => {
        //   var contentHeight = document.getElementById("content");
        //   contentHeight.scrollTop = contentHeight.scrollHeight;
        // });
        const url = "/api/AddchatServlet";
      let str = {
        CustomerIP: this.ip+this.zhiwen,
        Sender: this.ip+this.zhiwen,
        Recipient: "企小虎",
        Sendingtime:this.time,
        Messagestatus: 0,
        Messagecontent:text,
      };
      let str1 = qs.stringify(str);
      // console.log("参数", str1);
      this.axios
        .post(url, str1)
        .then((res) => {
          // this.remainingTime=60;
          // this.pauseTimer();
          // this.jishi();
          // this.$message({
          //     message: "发送成功！",
          //     type: "success",
          //   });
            this.customerText = "";
            this.kefuDialog();
        })
        .catch((err) => {
          //console.log(err),
           // console.log(err.response.data),
            // this.$message({
            //   message: "失败！请检查网络",
            //   type: "warning",
            // });
        });
      }
    },
    // 机器人回答消息
    appendRobotMsg(text) {
      clearTimeout(this.timer);
      this.showTimer();
      text = text.trim();
      let answerText = "";
      let flag;
      for (let i = 0; i < this.robotAnswer.length; i++) {
        if (this.robotAnswer[i].content.indexOf(text) != -1) {
          flag = true;
          answerText = this.robotAnswer[i].content;
          break;
        }
      }
      if (flag) {
        let obj = {
          type: "leftinfo",
          time: this.getTodayTime(),
          name: "robot",
          content: answerText,
          question: [],
        };
        this.info.push(obj);
      } else {
        answerText = "您可能想问：";
        let obj = {
          type: "leftinfo",
          time: this.getTodayTime(),
          name: "robot",
          content: answerText,
          question: this.robotQuestion,
        };
        this.info.push(obj);
      }
      this.$nextTick(() => {
        var contentHeight = document.getElementById("content");
        contentHeight.scrollTop = contentHeight.scrollHeight;
      });
    },
    sentMsgById(val, id) {
      clearTimeout(this.timer);
      this.showTimer();

      let robotById = this.robotAnswer.filter((item) => {
        return item.id == id;
      });
      let obj_l = {
        type: "leftinfo",
        time: this.getTodayTime(),
        name: "robot",
        content: robotById[0].content,
        question: [],
      };
      let obj_r = {
        type: "rightinfo",
        time: this.getTodayTime(),
        name: "robot",
        content: val,
        question: [],
      };
      this.info.push(obj_r);
      this.info.push(obj_l);
      this.$nextTick(() => {
        var contentHeight = document.getElementById("content");
        contentHeight.scrollTop = contentHeight.scrollHeight;
      });
    },
    // 点击机器人的单个问题
    clickRobot(val, id) {
      this.sentMsgById(val, id);
    },
    // 结束语
    // endMsg() {
    //   let happyEnding = {
    //     type: "leftinfo",
    //     time: this.getTodayTime(),
    //     content: "退下吧",
    //     question: [],
    //   };
    //   this.info.push(happyEnding);
    //   this.$nextTick(() => {
    //     var contentHeight = document.getElementById("content");
    //     contentHeight.scrollTop = contentHeight.scrollHeight;
    //   });
    // },
    showTimer() {
      this.timer = setTimeout(this.endMsg, 1000 * 60);
    },
    // getTodayTime() {
    //   // 获取当前时间
    //   var day = new Date();
    //   let seconds = day.getSeconds();
    //   if (seconds < 10) {
    //     seconds = "0" + seconds;
    //   } else {
    //     seconds = seconds;
    //   }
    //   let minutes = day.getMinutes();
    //   if (minutes < 10) {
    //     minutes = "0" + minutes;
    //   } else {
    //     minutes = minutes;
    //   }
    //   let time =
    //     day.getFullYear() +
    //     "-" +
    //     (day.getMonth() + 1) +
    //     "-" +
    //     day.getDate() +
    //     " " +
    //     day.getHours() +
    //     ":" +
    //     minutes +
    //     ":" +
    //     seconds;
    //   return time;
    // },
    async getIpAddress() {
try {
const response = await axios.get('https://qifu.baidu.com/ip/local/geo/v1/district');
this.ip = response.data.ip;
console.log(ip);
} catch (error) {
//console.error(error);
}
},
getDate(date) {
      //date是传过来的时间戳，注意需为13位，10位需*1000
      //也可以不传,获取的就是当前时间
      var time = new Date(date);
      var year = time.getFullYear(); //年
      var month = ("0" + (time.getMonth() + 1)).slice(-2); //月
      var day = ("0" + time.getDate()).slice(-2); //日
      var mydate = year + "-" + month + "-" + day;
      this.time=mydate+" "+`${("0" + time.getHours()).slice(-2)}:${("0" + time.getMinutes()).slice(-2)}:${("0" + time.getSeconds()).slice(-2)}`;
      console.log(this.time);
      return mydate;
    },
    selectchat(){
  this.timer=setInterval(()=>{
    const url = "/api/SetchatServlet";
      //let params = JSON.parse(sessionStorage.getItem('userInfo'));
      //console.log("参数", params);
      let str = {
        name: this.ip+this.zhiwen,
      };
      let str1 = qs.stringify(str);
      // console.log("参数", str1);
      this.axios
        .post(url, str1)
        .then((res) => {
          if(this.info.length-1!=res.data.length){
            this.info = [
            {
          Sender:"企小虎",
          Messagecontent:"您的知识产权管家，将竭诚为您服务：您可以直接咨询关于著作权和电子版权的所有问题，也可以拨打电话或者添加扫码微信号：18614063413",
        }
          ]; 
          //this.tableData = res.data;
          for(let i=0;i<res.data.length;i++){
            var obj = {
          Sender: res.data[i].Sender,
          Messagecontent:res.data[i].Messagecontent,
        };
          this.info.push(obj);
          this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        container.scrollTop = container.scrollHeight;
      });
      }
          }
          //this.Messagevolume=this.messages.length
        })
        .catch((err)=> {
         // console.log(err),
           // console.log(err.response.data),
            // this.$message({
            //   message: "失败！请检查网络",
            //   type: "warning",
            // });
        });
        this.count++;
  },1000);
},
createFingerprint() {
      // 浏览器指纹
      const fingerprint = Fingerprint2.get((components) => {
        // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
        const values = components.map((component) => component.value) // 配置的值的数组
        const murmur = Fingerprint2.x64hash128(values.join(''), 31) // 生成浏览器指纹
        // console.log(components, values,murmur,'你好') 
        //murmur就是浏览器指纹啦
        this.zhiwen=murmur;
      })
    },
  },
  mounted() {
    this.getIpAddress();
    const date = new Date();
    this.getDate(date);
    this.createFingerprint();
  },
  props: {},
  destroyed() {},
};
</script>
  <style lang="scss"scoped>

.bigBox{
  
  color: #fff;
  position: fixed;
  top: 40%;
  right: 2%;
  z-index: 1000;
}

  @font-face {
  font-family: 'iconfont';
  src: url('../assets/icon/iconfont.ttf') format('truetype');
}
.iconfont , .iconfont1 {
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  display: block;
  color: #4fabdf;
  margin-right: 20px;
  margin-top: 2px;
}
.iconfont{
  font-size: 20px;
}
.iconfont1 {
  font-size: 24px;
}
.iconfont2 , .iconfont3 , .iconfont4 {
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4fabdf;
  margin-right: 20px;
}
.iconfont2 {
  font-size: 20px;
}
.iconfont3 {
  font-size: 23px;
}
.iconfont4 {
  font-size: 16px;
}
.title{
  border-bottom: #9999995d 1px solid;
  padding-bottom: 20px;
}
.setproblem{
  // border: #9999995d 1px solid;
}
.sendbox{
  border-top: #9999995d 1px solid;
  // padding-top: 10px;
}
.sendbox_right_button button{
  width: 5.875rem;
  height: 1.7rem;
  line-height: 1.7rem;
  background: #4fabdf;
  opacity: 1;
  font-size: 12px;
  color: #ffffff;
  float: right;
  // margin-top: 10px;
}
.senbox_left__icon{
  // margin-top: 10px;
  width: 7rem;
  // border: #9999995d 1px solid;
  float: left;
}
.main {
  width: 600px;
  // height: 500px;
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 2000;
  border-radius: 15px;
  // height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(149, 179, 212, 1) 0%,
    rgba(74, 131, 194, 1) 100%
  );
  overflow: hidden;
  .box {
    width: 100%;
    /* width: 680px; */
    height: 570px;
    background-color: #fafafa;
    position: relative;
    padding: 1.25rem;
    #content {
      height: 340px;
      overflow-y: scroll;
      font-size: 14px;
      width: 100%;
      .circle {
        display: inline-block;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #eff1f3;
      }
      .con_text {
        color: #333;
        margin-bottom: 5px;
      }
      .con_que {
        color: #1c88ff;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
      }
      .info_r {
        position: relative;
        .circle_r {
          position: absolute;
          left: 0%;
        }
        .pic_r {
          width: 17px;
          height: 17px;
          margin: 8px;
        }
        .con_r {
          display: inline-block;
          /* max-width: 253px; */
          width: fit-content;
          //height: 55px;
          /* min-height: 20px; */
          background-color: #e2e2e2;
          border-radius: 6px;
          padding: 10px;
          margin-left: 40px;
          margin-right: 40%;
          word-break: break-all;
        }
        .time_r {
          margin-left: 45px;
          color: #999999;
          font-size: 12px;
        }
      }
      .info_l {
        text-align: right;
        // margin-right: 20px;
        color: #ffffff;
        color: #3163c5;
        margin-top: 10px;

        // .circle_l {
        //   // vertical-align: -10px;
        // }
        .pic_l {
          width: 34px;
          height: 34px;
          //margin: 8px 10px;
        }
        .time_l {
          margin-right: 45px;
          color: #999999;
          font-size: 12px;
          margin-top: 5px;
        }
        .con_l {
           display: inline-block;
           max-width: 220px;
          //min-height: 51px;
          background-color: #3163c5;
          border-radius: 6px;
          padding: 10px;
          text-align: left;
          color: #fff;
          margin-right: 5px;
          word-break: break-all;
        }
      }
      #question {
        cursor: pointer;
      }
    }
  }
}
.setproblem {
  width: 100%;
  height: 78px;
  background-color: #ffffff;
  position: relative;
  margin-top: 1rem;
}
.setproblem textarea {
  color: #999999;
  padding: 10px;
  box-sizing: border-box;
}
.setproblem button {
  width: 5.875rem;
  height: 2.5rem;
  line-height: 2.5rem;
  background: #3163c5;
  opacity: 1;
  border-radius: 4px;
  font-size: 10px;
  color: #ffffff;
  position: absolute;
  right: 5%;
  top: 30%;
  cursor: pointer;
  border: none;
}

.czkj-item-title {
  line-height: 25px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.czkj-item-question {
  cursor: pointer;
  display: block;
  padding: 8px;
  position: relative;
  border-bottom: 1px dashed #ccc;
  line-height: 20px;
  min-height: 20px;
  overflow: hidden;
}

.czkj-question-msg {
  float: left;
  font-size: 14px;
  color: #3163c5;
}
</style>