var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"nav-wrapper"},[_c('div',{staticClass:"nav-wrapper-row"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"bottomiInput"},[_c('div',{staticClass:"bottomiInputBox"},[_c('div',{staticClass:"bottomiInput_right"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" 请留下您的联系信息，我们会尽快安排专属管家为您提供定制化服务 ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',[_c('label',{attrs:{"for":"name"}},[_vm._v("姓名")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticStyle:{"width":"145px","margin-right":"25px"},attrs:{"type":"text","id":"name","name":"name","required":""},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('label',{attrs:{"for":"tel"}},[_vm._v("电话")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],staticStyle:{"width":"207px"},attrs:{"type":"text","id":"tel","name":"tel","required":""},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}})]),_c('div',[_c('label',{attrs:{"for":"note"}},[_vm._v("留言")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.note),expression:"note"}],staticStyle:{"width":"300px","margin-right":"10px"},attrs:{"type":"text","id":"note","name":"note","required":""},domProps:{"value":(_vm.note)},on:{"input":function($event){if($event.target.composing)return;_vm.note=$event.target.value}}}),_c('button',{attrs:{"type":"submit"}},[_vm._v("立即联系管家")])]),_vm._m(5)])])])])])])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-block",staticStyle:{"margin-right":"20px"}},[_c('span',{staticClass:"nav-block-title"},[_vm._v("关于我们")]),_c('div',{staticClass:"nav-block-content"},[_c('div',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"/about","rel":"nofollow"}},[_c('p',[_vm._v("公司介绍")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-block"},[_c('span',{staticClass:"nav-block-title"},[_vm._v("版权登记")]),_c('div',{staticClass:"nav-block-content"},[_c('div',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"/software","rel":"nofollow"}},[_c('p',[_vm._v("软件著作权")])]),_c('hr'),_c('a',{attrs:{"href":"/product","rel":"nofollow"}},[_c('p',[_vm._v("作品著作权")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-block"},[_c('span',{staticClass:"nav-block-title",staticStyle:{"width":"85px"}},[_vm._v("电子版权认证")]),_c('div',{staticClass:"nav-block-content"},[_c('div',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"/certificate","rel":"nofollow"}},[_c('p',[_vm._v("服务介绍")])]),_c('hr'),_c('a',{attrs:{"href":"/certificate","rel":"nofollow"}},[_c('p',[_vm._v("办理流程")])]),_c('hr'),_c('a',{attrs:{"href":"/certificate","rel":"nofollow"}},[_c('p',[_vm._v("办理材料")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-block",staticStyle:{"width":"146px"}},[_c('span',{staticClass:"nav-block-title"},[_vm._v("联系管家")]),_c('div',{staticClass:"nav-block-content"},[_c('div',{staticClass:"nav-item"},[_c('p',[_vm._v("7×24小时管家服务")]),_c('hr'),_c('p',[_vm._v("wuyulong@qixiaohu.cn")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-block",staticStyle:{"margin-left":"-30px"}},[_c('div',{staticStyle:{"padding-left":"35px"}},[_vm._v("扫码加微信")]),_c('img',{staticClass:"wechaterweima",attrs:{"src":require("@/assets/qrcode-01.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("*信息保护中，请放心填写*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","opacity":"0.6","font-size":"12px","padding-bottom":"30px"}},[_c('div',{staticStyle:{"display":"inline","margin-right":"60px"}},[_vm._v(" 公司地址：北京市顺义区顺畅大道14号院1号楼6层 ")]),_c('div',{staticStyle:{"margin-top":"10px","border-bottom":"1px solid hsla(0, 0%, 100%, 0.1)","padding-bottom":"30px","display":"inline"}},[_vm._v(" 版权所有：北京智链创新科技有限公司"),_c('span',{staticStyle:{"margin-left":"60px"}},[_vm._v("京ICP备2024074692号-1")])])])
}]

export { render, staticRenderFns }